.engagement-rating {
    display: flex;
    font-size: 15px;
    font-weight: 700;
    padding-left: 4px;
    align-items: center;
    justify-content: flex-end;

    span {
        font-size: 10px;
        padding-right: 8px;
    }

    .columns {
        display: flex;
        margin-left: 3px;
        align-items: flex-end;

        .column {
            width: 5px;
            height: 12px;
            margin-left: 1px;
            margin-top: -5px;
            background-color: #BBBBBB;

            &:first-child {
                height: 8px;
            }
            
            &:last-child {
                height: 16px;
            }

            &.active {
                background-color: #038d00;
            }
        }
    }
}