@import "../../styles/colors.scss";

.home-page {
    width: 100%;
    height: 100%;

    .home-content {
        margin: 0 auto;
        max-width: 1280px;
        overflow-x: hidden;
        padding: 28px 20px 8px;
        box-sizing: content-box;

        .card-pattern {
            padding: 24px 16px;
            border-radius: 4px;
            text-align: center;
            margin-bottom: 20px;
            color: getColor(text, gray);
            background-color: getColor(background, light);
            box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.06), 0 1px 8px 0 rgba(0, 0, 0, 0.13);

            .card-title {
                font-size: 20px;
                font-weight: 300;
                margin-bottom: 20px;
            }

            .gauge {
                margin-bottom: 20px;

            }

            .title-filter {
                font-size: 15px;
                margin-bottom: 10px;

            }

            .tab-filters {
                display: flex;
                margin-bottom: 20px;

                .filter {
                    padding: 5px;
                    cursor: pointer;
                    color: getColor(primary);
                    transition: 0.2s ease-in all;
                    border: 1px solid getColor(primary);
                    // 
                    border-left: none;

                    &:first-child {
                        border-radius: 4px 0 0 4px;
                        border-left: 1px solid getColor(primary);
                    }

                    &:last-child {
                        border-radius: 0 4px 4px 0;
                    }

                    &.active {
                        color: getColor(text, white);
                        background-color: getColor(primary);
                    }
                }
            }

            .airscore-changes {
                height: 148px;
                border-radius: 4px;
                background-color: getColor(background, lightest);

                .empty {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    color: getColor(text, gray, .5);
                }

                .ac-top,
                .ac-bottom {
                    display: flex;
                    padding: 12px;
                    justify-content: space-between;

                    span,
                    i {
                        transition: all 0.2s ease-in-out;
                    }
                }

                .ac-middle {
                    height: 86px;
                    display: flex;
                    padding: 0 12px;
                    align-items: center;
                    justify-content: center;
                    border-top: 1px solid getColor(background, light);
                    border-bottom: 1px solid getColor(background, light);
                }

                // text
                .ac-text {
                    font-size: 12px;
                }

                .empty,
                .airscore-progress {
                    opacity: 1;
                    animation-duration: .2s;
                    animation-name: fadeInOpacity;
                    animation-timing-function: ease-in;
                }

                @keyframes fadeInOpacity {
                    0% {
                        opacity: 0;
                    }

                    100% {
                        opacity: 1;
                    }
                }
            }

            &.card-interactions {
                .card-value {
                    padding: 12px;
                    border-radius: 4px;
                    margin-bottom: 10px;
                    background-color: getColor(background, lightest);

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .title-value-card {
                        font-size: 10px;
                        text-align: left;
                        margin-bottom: 10px;
                    }

                    .icon-value {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        &.icon-value-empty {
                            margin: 20px 0 4px;
                            .empty {
                                opacity: 0.7;
                                font-size: 12px;
                                text-transform: uppercase;
                            }

                            .icon-tooltip {
                                opacity: 0.7;
                                font-size: 15px;
                                margin-left: 6px;
                            }
                        }

                        .icon {
                            font-size: 35px;
                            margin-right: 12px;
                        }


                        .quant {
                            font-size: 16px;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .home-page {
        .home-content {
            display: grid;
            padding: 45px 35px;
            grid-gap: 35px 35px;
            grid-template-columns: 1fr 1fr;

            .card-pattern {
                margin: 0;
                padding: 35px 20px;

                .card-title {
                    font-size: 25px;
                    margin-bottom: 35px;
                }

                .gauge {
                    margin-bottom: 35px;

                }

                .title-filter {
                    font-size: 20px;
                    margin-bottom: 10px;
                }

                .tab-filters {
                    margin-bottom: 35px;

                    .filter {
                        padding: 6px;
                        font-size: 20px;
                    }
                }

                .airscore-changes {
                    height: 204px;

                    .ac-top,
                    .ac-bottom {
                        padding: 15px;
                    }

                    .ac-middle {
                        padding: 0 15px;
                    }

                    // text
                    .ac-text {
                        font-size: 16px;
                    }
                }

                &.card-airscore {
                    grid-column: span 2;
                }


                &.card-interactions {
                    .card-value {
                        padding: 16px;
                        margin-bottom: 16px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .title-value-card {
                            font-size: 16px;
                        }

                        .icon-value {
                            .icon {
                                font-size: 50px;
                                margin-right: 12px;
                            }

                            .quant {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }


}

@media screen and (min-width: 1024px) {
    .home-page {
        .container-loading {
            min-height: 300px;
        }

        .home-content {
            grid-gap: 41px;
            max-width: 1440px;
            padding: 54px 47px;
            grid-template-columns: 5fr 4fr 4fr;

            .card-pattern {
                margin: 0;
                padding: 41px 35px;

                .card-title {
                    font-size: 24px;
                    margin-bottom: 48px;
                }

                .gauge {
                    margin-bottom: 48px;

                }

                .title-filter {
                    font-size: 14px;
                }

                .tab-filters {
                    margin-bottom: 26px;

                    .filter {
                        padding: 4px;
                        font-size: 14px;
                    }
                }

                .airscore-changes {
                    height: 226px;

                    .container-loading {
                        min-height: 100%;
                    }

                    .ac-top,
                    .ac-bottom {
                        padding: 24px 12px;
                    }

                    .ac-middle {
                        padding: 0 12px;
                    }

                    // text
                    .ac-text {
                        font-size: 14px;
                    }
                }

                &.card-airscore {
                    display: flex;
                    grid-row: span 2;
                    grid-column: span 1;
                    flex-direction: column;
                    justify-content: space-between;

                }

                &.card-interactions {
                    .card-title {
                        margin-bottom: 20px;
                    }

                    .card-value {
                        &:last-child {
                            margin-bottom: 0;
                        }

                        .title-value-card {
                            font-size: 14px;
                        }

                        .icon-value {
                            .icon {
                                font-size: 37px;
                                margin-right: 23px;
                            }

                            .quant {
                                font-size: 24px;
                            }
                        }
                    }
                }
            }
        }
    }
}