@import "../../../styles/colors.scss";

.ant-tooltip-arrow {
    .ant-tooltip-arrow-content {
        background-color: getColor(background, dark);
    }
}
.ant-tooltip {
    .ant-tooltip-inner {
        max-width: 323px;
        letter-spacing: 0.33px;
        background-color: getColor(background, dark);
    }
}