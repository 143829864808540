@import "../../../styles/colors.scss";

.ant-modal-wrap {
    display: flex;
    // overflow: hidden;
    align-items: center;
}

.modal {
    top: 0 !important;
    width: 70% !important; 
    max-width: 687px !important;
    border-radius: 50px;

    .ant-modal-content {
        border-radius: 6px;

        .ant-modal-header {
            display: none;
        }

        .ant-modal-footer {
            display: none;
        }

        .ant-modal-body {
            display: flex;
            padding: 24px 0;
            min-height: 100px;

            .container-icon {
                top: 0;
                width: 15%;
                display: flex;
                height: 100%;
                position: absolute;
                align-items: center;
                justify-content: center;
                border-radius: 6px 0 0 6px;
                background-color: getColor(primary);

                &.container-info {
                    background-color: getColor(status, info);
                }

                &.container-error {
                    background-color: getColor(status, error);
                }

                &.container-success {
                    background-color: getColor(status, success);
                }

                &.container-warning {
                    background-color: getColor(status, warning);
                }

                .icon {
                    font-size: 30px;
                    color: getColor(text, white);
                }
            }

            .container-content {
                width: 100%;
                padding-right: 30px;
                padding-left: calc(15% + 30px);

                .title {
                    font-size: 20px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 24px;
                    margin-bottom: 24px;
                    text-transform: uppercase;

                    &.title-info {
                        color: getColor(status, info);
                    }

                    &.title-error {
                        color: getColor(status, error);
                    }

                    &.title-success {
                        color: getColor(status, success);
                    }

                    &.title-warning {
                        color: getColor(status, warning);
                    }
                }

                .text, .question {
                    font-size: 16px;
                    line-height: 28px;
                    letter-spacing: 0.26px;
                }

                .question {
                    margin: 40px 0 32px;
                    font-weight: bold;
                }

                .buttons {
                    display: flex;
                    justify-content: flex-end;
                    div:last-child {
                        margin-left: 20px;
                    }
                }
            }
        }
    }
}

.ant-modal-footer {
    display: none;
}