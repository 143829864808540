@import "../../../styles/colors.scss";

.grow-decrease {
    display: flex;
    font-size: 12px;
    font-weight: normal;
    color: getColor(text, gray, .5);

    .style-value {
        display: flex;
        cursor: default;
        margin-left: 5px;
        align-items: center;

        .icon {
            font-size: 12px;
            margin: 0 0 0 3px;

            &.icon-growth {
                color: getColor(status, success) !important;
            }

            &.icon-fall {
                color: getColor(status, error) !important;
            }
        }
    }

    &.growth {
        color: getColor(status, success) !important;
    }

    &.fall {
        color: getColor(status, error) !important;
    }
}


@media screen and (min-width: 768px) {
    .grow-decrease {
        font-size: 16px;

        .style-value {
            margin-left: 5px;
            .icon {
                font-size: 20px;
                margin: 0;
                
                &.icon-growth {
                    margin-top: -3px;
                    color: getColor(status, success) !important;
                }
                
                &.icon-fall {
                    margin-top: -6px;
                    color: getColor(status, error) !important;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .grow-decrease {
        font-size: 14px;

        .style-value {
            .icon {
                font-size: 16px;
                
                &.icon-growth {
                    margin-top: 0px;
                    color: getColor(status, success) !important;
                }
                
                &.icon-fall {
                    margin-top: 0px;
                    color: getColor(status, error) !important;
                }
            }
        }
    }
}