@import "./styles/colors.scss";
@import "./styles/icons.scss";
// 
@import '~antd/dist/antd.css';

*, body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

body {
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  #root {
    height: 100%;
  }
}


/****************/
/*** NETWORKS ***/
/****************/
.color-instagram, .color-ig {
  color: getColor(socials, instagram) !important;
}
.border-color-instagram, .border-color-ig {
  border-color: getColor(socials, instagram) !important;
}
.background-instagram, .background-ig {
  background-color: getColor(socials, instagram) !important;
}
//
.color-facebook, .color-fb {
  color: getColor(socials, facebook) !important;
}
.border-color-facebook, .border-color-fb {
  border-color: getColor(socials, facebook) !important;
}
.background-facebook, .background-fb {
  background-color: getColor(socials, facebook) !important;
}
//
.color-twitter, .color-tw {
  color: getColor(socials, twitter) !important;
}
.border-color-twitter, .border-color-tw {
  border-color: getColor(socials, twitter) !important;
}
.background-twitter, .background-tw {
  background-color: getColor(socials, twitter) !important;
}
//
.color-youtube, .color-yt {
  color: getColor(socials, youtube) !important;
}
.border-color-youtube, .border-color-yt {
  border-color: getColor(socials, youtube) !important;
}
.background-youtube, .background-yt {
  background-color: getColor(socials, youtube) !important;
}
// 
.color-pinterest, .color-pt {
  color: getColor(socials, pinterest) !important;
}
.border-color-pinterest, .border-color-pt {
  border-color: getColor(socials, pinterest) !important;
}
.background-pinterest, .background-pt {
  background-color: getColor(socials, pinterest) !important;
}

/********************/
/*** INTERACTIONS ***/
/********************/
.color-views {
  color: getColor(interactions, views) !important;
}
.border-color-views {
  border-color: getColor(interactions, views) !important;
}
.background-views {
  background-color: getColor(interactions, views) !important;
}
// 
.color-comments {
  color: getColor(interactions, comments) !important;
}
.border-color-comments {
  border-color: getColor(interactions, comments) !important;
}
.background-comments {
  background-color: getColor(interactions, comments) !important;
}
// 
.color-likes {
  color: getColor(interactions, likes) !important;
}
.border-color-likes {
  border-color: getColor(interactions, likes) !important;
}
.background-likes {
  background-color: getColor(interactions, likes) !important;
}
// 
.color-hashtags {
  color: getColor(interactions, hashtags) !important;
}
.border-color-hashtags {
  border-color: getColor(interactions, hashtags) !important;
}
.background-hashtags {
  background-color: getColor(interactions, hashtags) !important;
}
// 



/******************/
/** PATTERN PAGE **/
/******************/
.pattern-page {
  overflow-y: auto;
  margin-top: 60px;
  height: calc(100% - 60px);
}

@media screen and (min-width: 768px) {
  .pattern-page {
    margin-top: 80px;
    height: calc(100% - 80px);
  }
}
@media screen and (min-width: 1024px) {
  ::-webkit-scrollbar {   // width
    width: 7px;
  }
  
  ::-webkit-scrollbar-track {   // track
    background: #f1f1f1; 
  }
  
  ::-webkit-scrollbar-thumb {   // handle
    background: #888; 
    &:hover {
      background: rgb(97, 97, 97);  
    }
  }
  
  body {
    overflow-y: auto;

    .pattern-page {
      height: auto;
      margin-top: 0;
      overflow-y: hidden;
      box-sizing: border-box;
    }
  }
}