@import "../../../styles/colors.scss";

.circle-progress {
    position: relative;

    .info-progress {
        top: 50%;
        left: 50%;
        z-index: 1;
        position: absolute;
        transform: translate(-50%, -50%);

        .info {
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .icon {
                padding: 5px 0 5px 5px;
            }
        }

        .progress {
            font-size: 35px;
            color: getColor(primary);
        }
    }
}


@media screen and (min-width: 768px) {
    .circle-progress {
        .info-progress {
            .info {
                font-size: 20px;                
                .icon {
                    padding: 5px 0 5px 5px;
                }
            }

            .progress {
                font-size: 50px;
            }
        }

        .ant-progress {
            .ant-progress-inner {
                width: 275px !important;
                height: 275px !important;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .circle-progress {
        .info-progress {
            .info {
                font-size: 20px;                
                .icon {
                    padding: 5px 0 5px 5px;
                }
            }

            .progress {
                font-size: 50px;
            }
        }

        .ant-progress {
            .ant-progress-inner {
                width: 275px !important;
                height: 275px !important;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .circle-progress {
        .info-progress {
            .info {
                font-size: 16px;                
                .icon {
                    padding: 5px 0 5px 5px;
                }
            }

            .progress {
                font-size: 50px;
            }
        }

        .ant-progress {
            .ant-progress-inner {
                width: 200px !important;
                height: 200px !important;
            }
        }
    }
}