@import "../../../styles/colors.scss";

.bar-progress {
    width: 100%;
    position: relative;

    .progress-label {
        left: 0;
        width: 20px;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        position: absolute;
        color: getColor(primary);
        transform: translate(-50%, -90%);
    }
    
    .progress-bar {
        height: 8px;
        border-radius: 4px;
        position: relative;
        background-color: getColor(background, medium);
        .progress-value {
            left: 0;
            height: 100%;
            border-radius: 4px;
            position: absolute;
            transition: 0.2s ease-in all;
            background-color: getColor(primary);
        }
        &::after,
        &::before {
            top: 50%;
            font-size: 8px;
            position: absolute;
            transform: translateY(60%);
            color: getColor(text, lightGray);
        }

        &::after {
            left: 0;
            content: '0';
            transform: translate(-50%, 60%);
        }

        &::before {
            right: 0;
            content: '1000';
        }
    }
}


@media screen and (min-width: 768px) {
    .bar-progress {
        .progress-label {
            width: 40px;
            font-size: 16px;
            transform: translate(-50%, -100%);
        }
    }
}

@media screen and (min-width: 1024px) {
    .bar-progress {
        .progress-label {
            font-size: 13px;
            transform: translate(-50%, -100%);
        }
    }
}