@import "../../../styles/colors.scss";

.container-header {
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    z-index: 999;
    display: flex;
    padding: 0 20px;
    position: absolute;
    align-items: center;
    box-sizing: border-box;
    background-color: getColor(background, light);
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.06), 0 1px 8px 0 rgba(0, 0, 0, 0.059);

    .header {
        width: 100%;
        display: flex;
        margin: 0 auto;
        align-items: center;
        justify-content: space-between;

        .logo {
            height: 30px;
            cursor: pointer;
        }

        .username {
            margin: 0;
            font-size: 12px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 1px;
        }
    }
}

@media screen and (min-width: 768px) {
    .container-header {
        height: 80px;
        padding: 0 35px;

        .header {
            .logo {
                height: 45px;
            }

            .username {
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 1px;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .container-header {
        height: 98px;
        padding: 0 47px;
        box-shadow: none;
        position: relative;

        .header {
            max-width: 1440px;

            .logo {
                height: 45px;
            }

            .username {
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 1px;
            }
        }
    }
}