@import "../../../styles/colors.scss";

.container-loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-spin-dot {
        .ant-spin-dot-item {
            background-color: getColor(primary);
        }
    }
}
